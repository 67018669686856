<template>
  <v-col cols="12">
    <SlickList
      lockAxis="y"
      v-model="course.modules"
      :useDragHandle="true"
      @sort-start="startOrder"
      @sort-end="saveOrder"
    >
      <SlickItem
        v-for="(item, index) in course.modules"
        :index="index"
        :key="index"
      >
        <f-module-list-item :item="item" :key="item._id" />
      </SlickItem>
    </SlickList>
    <!-- <div
      class="waiting-save"
      :class="{
        active: order.willBeSave,
        success: order.saved,
      }"
    >
      {{
        order.saved
          ? $t('alerts.courses.successSavedOrder')
          : $t('alerts.courses.llSaveOrder')
      }}
    </div>

    <a class="add-module-btn" @click="add" v-if="allowEdit">
      <v-icon>mdi-plus</v-icon>
    </a> -->
  </v-col>
</template>

<script lang="js">
import { SlickList, SlickItem } from "vue-slicksort";
import FModuleListItem from './ModuleListItem.vue';
import FAddButton from './AddButton.vue';

export default {
	components: {
		FModuleListItem,
		SlickList,
		SlickItem,
    FAddButton
	},
	// data: () => ({
	// 	order: {
  //     timeoutCtl: null,
  //     willBeSave: false,
  //     saved: false
  //   },
	// }),
	computed: {
    order() {
      return this.$store.state.system.listControl.order
    },
		// allowEdit() {
    //   return (
    //     this.$store.state.auth.user.type == UserType.Admin ||
    //     this.$store.state.auth.user.type == UserType.TSM
    //   );
    // },
		course() {
			return this.$store.state.course.current
		},
		// currentDescription() {
    //   return stringsHelper.convertBreakLinesToBrTag(
    //     this.course.description[this.currentLanguage]
    //   );
    // },
		courseTitle() {
      return this.course.title[this.courseCurrentLanguage];
    },
    courseCurrentLanguage() {
      return this.$store.getters['course/courseCurrentLanguage'];
    },
	},
	methods: {
		add() {
      this.$store.commit("questions/setAdding", false);

      let routeName = "course-module-create"
      if(this.course.structure == 'linear'){
        routeName = "course-linear-lesson-create"
      }
      this.$router.push({
        name: routeName,
        params: {
          courseid: this.$route.params.id
        }
      });
    },
		saveOrder() {
      this.order.saved = false;
      this.order.timeoutCtl = setTimeout(
        async function() {
          this.course.modules = this.course.modules.map((item, index) => {
            item.position = index;
            return item;
          });
          await this.$store.dispatch("course/module_ordersave", {
            modules: this.course.modules,
            course_id: this.id
          });
          this.$store.commit("snackbar/show", {
            content: this.$t("alerts.courses.successSavedOrder"),
            type: "success"
          });
          this.order.saved = true;
          setTimeout(
            function() {
              this.order.willBeSaved = false;
              setTimeout(
                function() {
                  this.order.saved = false;
                }.bind(this),
                800
              );
            }.bind(this),
            2000
          );
        }.bind(this),
        2000
      );
    },
		startOrder() {
      this.order.willBeSaved = true;
      clearTimeout(this.order.timeoutCtl);
    },
	},
}
</script>
