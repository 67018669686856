<template>
  <v-dialog v-model="dialogUsers" scrollable max-width="500px">
    <v-card>
      <v-card-title>
        <span style="margin-right: 50px">{{
          $i18n.t('labels.examAttempts')
        }}</span>
        <v-text-field
          v-model="examAttemptFilter"
          :label="$i18n.t('labels.search')"
          hide-details
          outlined
        ></v-text-field>
      </v-card-title>

      <v-divider />

      <v-card-text style="height: 300px; padding: 20px">
        <div v-if="examAttemptsLoading">
          <v-progress-circular indeterminate color="primary" />
        </div>

        <div v-if="!examAttemptsLoading && examUsers.length > 0">
          <f-user-exam-attempt
            v-for="(item, index) of examUsersFiltered"
            :key="index"
            :item="item"
            :loading="examAttemptsLoading"
            @change="onChangeAttempts"
          />
        </div>

        <div v-else>
          {{ $i18n.t('labels.noAttempt') }}
        </div>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn color="blue darken-1" text @click="dialogUsers = false">
          {{ $i18n.t('labels.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="js">
import FUserExamAttempt from '../Users/UserExamAttempt.vue';

export default {
	components: {
		FUserExamAttempt
	},
	data: () => ({
		examAttemptFilter: '',
	}),
	computed: {
		dialogUsers: {
			get() {
				return this.$store.state.course.dialogUsers;
			},
			set(value) {
				this.$store.state.course.dialogUsers = value;
			},
		},
		examAttemptsLoading() {
			return this.$store.state.course.examAttemptsLoading
		},
		examUsers() {
			return this.$store.state.course.examUsers
		},
		examUsersFiltered() {
      const examAttemptFilterTokenized = this.examAttemptFilter
        ? this.examAttemptFilter
        : '';
      return this.examUsers.filter(item => {
        if (examAttemptFilterTokenized) {
          return (
            item.name.includes(examAttemptFilterTokenized) ||
            item.email.includes(examAttemptFilterTokenized)
          );
        }
        return true;
      });
    },
	},
	methods: {
		async onChangeAttempts(item, attempts) {
      await this.$store.dispatch(
        'course/module_exam_set_user_attempts',
        {
          userID: item._id,
          courseID: this.$route.params.id,
          moduleID: this.$store.state.course.dialogLessonData.lessonID,
          attempts,
        }
      );
    },
	}
}
</script>
