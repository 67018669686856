<template>
  <div
    class="module_row"
    @click="view(item._id)"
    :class="{ viewed: progress.length > 0 && progress[item._id] }"
  >
    <div
      class="llr--icon"
      v-allowed="[userTypes.STUDENT, userTypes.MASTER, userTypes.TSM]"
    >
      <v-icon
        color="primary"
        v-if="isLessonDone"
        :class="{
          notApproved: item.category == 2 && !examResult(item).approved,
        }"
      >
        {{
          item.category == 2 && !examResult(item).approved
            ? 'mdi-alert-circle'
            : 'mdi-checkbox-marked-circle'
        }}
      </v-icon>
      <v-icon v-else>
        mdi-checkbox-blank-circle-outline
      </v-icon>
    </div>

    <span v-handle class="handle" v-allowed="[userTypes.ADMIN]">
      <v-icon>mdi-dots-grid</v-icon>
    </span>
    {{ getTitle(item) }}

    <div class="admin-actions">
      <div class="f-rating-system" v-if="!allowEdit">
        <f-rating-system
          v-if="item.category !== 2"
          :rating="{
            size: 28,
            parentID: item._id,
            isReadonly: true,
            value: item.average_rating_module || 0,
          }"
        />
        <div v-else class="f-progress-user">
          <div v-if="currentProgress != 100">-</div>
          <div v-else :class="{ approved: examResult().approved }">
            {{ examResult().perc }}% ({{ examResult().result }}/{{
              examResult().max
            }})
          </div>
        </div>
      </div>
      <div class="f-admin-options" v-allowed="[userTypes.ADMIN]">
        <div
          class="checkIcon"
          v-if="item.category == '2' && !getCheck(item._id)"
        >
          <v-icon>mdi-exclamation</v-icon>
        </div>
        <div
          class="exam-progress"
          v-if="
            item.category == '2' && getModuleProgress(item._id, true) !== false
          "
        >
          {{ getMaxScore(item) }}
        </div>
        <div v-allowed="[userTypes.ADMIN]">
          <v-icon
            color="primary"
            v-if="isLessonDone"
            :class="{
              notApproved: item.category == 2 && !examResult(item).approved,
            }"
          >
            {{
              item.category == 2 && !examResult(item).approved
                ? 'mdi-alert-circle'
                : 'mdi-checkbox-marked-circle'
            }}
          </v-icon>
          <v-icon v-else>
            mdi-checkbox-blank-circle-outline
          </v-icon>
        </div>
        <a @click.stop="openDialogAnswers(item._id)" v-if="canSeeAnswers(item)">
          <v-icon>mdi-order-bool-ascending-variant</v-icon>
        </a>
        <a @click.stop="openModalUsers(item._id)" v-if="item.category == '2'">
          <v-icon>mdi-account-multiple</v-icon>
        </a>
        <a @click.stop="lessonEdit(item._id)" v-allowed="[userTypes.ADMIN]">
          <v-icon>mdi-pencil</v-icon>
        </a>
        <a @click.stop="lessonRemove(item._id)" v-allowed="[userTypes.ADMIN]">
          <v-icon>mdi-delete</v-icon>
        </a>
      </div>
    </div>
    <f-exam-answers
      v-if="true"
      :active="dialogAnswers"
      :loading="examAnswersLoading"
      :module="examAnswers.module"
      :lastExamAttempt="examAnswers.lastExamAttempt"
      :language="$store.getters['course/courseCurrentLanguage']"
      @close="closeDialogAnswers"
    />
  </div>
</template>

<script>
import { HandleDirective } from 'vue-slicksort';
import FRatingSystem from '../shared/Rating.vue';
import { isLessonDone as checkLessonDone } from '../../services/progress';
import { isLinear } from '../../services/courses';
import FExamAnswers from './ExamAnswers.vue';

export default {
  props: ['item', 'moduleID'],
  components: {
    FRatingSystem,
    FExamAnswers,
  },
  directives: {
    handle: HandleDirective,
  },
  data: () => ({
    progress: [],
    isLessonDone: false,
    currentModuleID: null,
    dialogAnswers: false,
    examAnswers: {},
    examAnswersLoading: false,
  }),
  computed: {
    authUser() {
      return this.$store.getters['auth/user'];
    },
    allowEdit() {
      return (
        this.authUser.type == this.userTypes.ADMIN ||
        this.authUser.type == this.userTypes.TSM
      );
    },
    course() {
      return this.$store.getters['course/current'];
    },
    courseCurrentLanguage() {
      return this.$store.getters['course/courseCurrentLanguage'];
    },
    currentProgress() {
      try {
        return this.$store.state.course.progress.filter(
          item => item.moduleID == this.item._id
        )[0].progress;
      } catch (e) {
        return 0;
      }
    },
  },
  created() {
    this.verifyLessonDone();
  },
  methods: {
    verifyLessonDone() {
      if (this.course.structure === 'linear') {
        this.isLessonDone = checkLessonDone({
          courseID: this.$route.params.id,
          lessonID: this.item._id,
        });
      } else {
        this.isLessonDone = checkLessonDone(
          {
            courseID: this.$route.params.id,
            lessonID: this.item._id,
            moduleID: this.moduleID,
          },
          'modular'
        );
      }
    },
    examResult() {
      try {
        const courseDataAttempts = this.$store.getters[
          'auth/user'
        ].examAttempts.find(item => item.courseID == this.$route.params.id);

        let moduleID = this.moduleID;
        if (!moduleID) moduleID = this.item._id;
        const userModuleScore = courseDataAttempts.modules
          .find(item => item.moduleID == moduleID)
          .list.slice(-1)
          .pop().score;

        return {
          max: this.item.data.questions.length,
          min: this.item.data.min_questions,
          result: userModuleScore,
          perc: (
            (userModuleScore * 100) /
            this.item.data.questions.length
          ).toFixed(1),
          approved: userModuleScore >= this.item.data.min_questions,
        };
      } catch (e) {
        return false;
      }
    },
    getTitle(lesson) {
      try {
        return lesson.title[this.courseCurrentLanguage];
      } catch (e) {
        return '*corrupted data lesson';
      }
    },
    canSeeAnswers(lesson) {
      if (lesson.category != 2) return false;
      return checkLessonDone(
        {
          lessonID: lesson._id,
          moduleID: this.moduleID,
          courseID: this.$route.params.id,
        },
        isLinear() ? 'linear' : 'modular'
      );
    },
    getCheck(id) {
      const progress = this.$store.getters['auth/user'].progress;
      if (!progress) {
        return false;
      }
      const mdl = this.$store.getters['course/current'].modules.find(
        item => item._id == id
      );
      const progressItem = progress.find(item => item.moduleID === id);
      if (mdl && progressItem) {
        if (+mdl.category === 2) {
          const attempts = this.attempts[mdl._id];
          return moduleUtils.hasPassed(attempts, mdl.data);
        } else {
          return +progressItem.progress >= 100;
        }
      }
      return false;
    },
    getModuleProgress(id, current = false) {
      try {
        const moduleData = this.currentProgress.find(
          item => item.moduleID == id
        );
        if (typeof moduleData == 'undefined') {
          return current ? false : 0;
        } else {
          return current ? moduleData.current : moduleData.progress;
        }
      } catch (e) {
        return current ? false : 0;
      }
    },
    view(id) {
      let gtmLabel = '';
      if (this.course.structure == 'linear') {
        this.$router.push({
          name: 'course-linear-lesson-view',
          params: {
            courseid: this.course._id,
            lessonid: id,
          },
        });
        gtmLabel = `course-details-${this.course._id}-linear-${id}`;
      } else {
        this.$router.push({
          name: 'course-modular-lesson-view',
          params: {
            courseid: this.course._id,
            moduleid: this.moduleID,
            lessonid: this.item._id,
          },
        });
        gtmLabel = `course-details-${this.course._id}-modular-${this.moduleID}-${id}`;
      }
      this.$gtm.trackEvent({
        category: 'course-details',
        event: 'click',
        action: 'course-details-click',
        label: gtmLabel,
        value: `view-${this.course._id}`,
      });
    },
    lessonEdit(id) {
      const courseId = this.$route.params.id;

      if (this.$store.getters['course/isLinear']) {
        this.$router.push({
          name: 'course-linear-lesson-edit',
          params: {
            courseid: courseId,
            lessonid: id,
          },
        });
      } else {
        this.$router.push({
          name: 'course-modular-lesson-edit',
          params: {
            courseid: courseId,
            moduleid: this.moduleID,
            lessonid: id,
          },
        });
      }

      this.$gtm.trackEvent({
        category: 'course-admin-actions',
        event: 'click',
        action: 'course-click',
        label: 'edit-lesson',
        value: `course-${courseId}-lesson-${id}`,
      });
    },
    async lessonRemove(id) {
      const courseId = this.$route.params.id;

      if (this.$store.getters['course/isLinear']) {
        await this.$store.dispatch('lesson/remove', id);
      } else {
        this.$store.dispatch('module/select', this.moduleID);
        await this.$store.dispatch('lesson/remove', id);
        this.$store.commit('module/clear');
      }

      this.$store.commit('system/loading');
      this.$store.commit('course/clearCurrent');
      await this.$store.dispatch('course/get', this.$route.params.id);
      this.$store.commit('system/pageLoaded');

      this.$gtm.trackEvent({
        category: 'course-admin-actions',
        event: 'click',
        action: 'course-click',
        label: 'remove-lesson',
        value: `course-${courseId}-lesson-${id}`,
      });
    },
    async openModalUsers(lessonID) {
      const id = this.$store.getters['course/current']._id;
      const result = await this.$store.dispatch(
        'course/module_exam_list_users_attempts',
        {
          courseID: id,
          moduleID: lessonID,
        }
      );

      this.$gtm.trackEvent({
        category: 'course-admin-actions',
        event: 'click',
        action: 'course-click',
        label: 'search-modul-user',
        value: `course-${id}`,
      });
    },
    async openDialogAnswers(lessonID) {
      this.currentModuleID = lessonID;
      this.dialogAnswers = true;
      this.examAnswers = {};
      this.examAnswersLoading = true;
      const result = await this.$store.dispatch('course/module_exam_answers', {
        courseID: this.$route.params.id,
        lessonID,
        moduleID: this.moduleID,
      });
      if (+result.status === 1) {
        this.examAnswers = result.body;
      }
      this.examAnswersLoading = false;
    },
    closeDialogAnswers() {
      this.dialogAnswers = false;
    },
  },
};
</script>

<style lang="scss">
@import '../../assets/scss/base/variables';

.module_row {
  display: flex;
  align-items: center;
  height: 50px;
  background-color: $box-bg;
  padding: 0 15px;
  border-bottom: 1px solid $global-bg;
  position: relative;
  /* border-right: 5px solid #ffffff; */
  /* cursor: pointer; */

  .llr--icon {
    margin-right: 22px;
  }

  &:hover {
    background-color: $box-hover;
  }

  &.viewed {
    border-right: 5px solid rgba(0, 0, 0, 0.54);
  }

  .admin-actions {
    position: absolute;
    right: 10px;
    width: 224px;
    display: flex;
    align-items: center;
    justify-content: end;

    @media only screen and (max-width: 850px) {
      display: none !important;
    }

    a {
      display: inline-block;
      border-radius: 3px;
      width: 35px;
      height: 35px;
      margin-left: 10px;
      text-align: center;
      padding-top: 4px;
    }
  }
  .checkIcon,
  .exam-progress {
    display: inline-block;
    margin-right: 10px;
  }
}
.add-module-btn {
  display: flex;
  align-items: center;
  height: 50px;
  background-color: #ffc107;
  justify-content: center;
}
.f-rating-system {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;

  .v-rating .v-icon {
    font-size: 24px !important;
  }
}
.f-admin-options {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
}
.f-progress-user {
  margin-right: 12px;
  font-weight: 500;

  div {
    color: #fb8282;

    &.approved {
      color: green;
    }
  }
}
</style>
