<template>
  <v-col cols="12" v-if="loaded">
    <div v-if="!lessons.length">
      nao ha items disponiveis
    </div>
    <SlickList
      lockAxis="y"
      v-model="lessons"
      :useDragHandle="true"
      @sort-start="startOrder"
      @sort-end="saveOrder"
      v-if="lessons.length"
    >
      <SlickItem v-for="(item, index) in lessons" :index="index" :key="index">
        <f-lesson-list-item :item="item" :moduleID="moduleID" />
      </SlickItem>
    </SlickList>

    <f-list-warning />
  </v-col>
</template>

<script>
import stringsHelper from '../../helpers/strings';
import { UserType } from '../../helpers/user_utils';
import { SlickList, SlickItem } from 'vue-slicksort';
import FLessonListItem from './LessonListItem.vue';
import FAddButton from './view/AddButton.vue';
import FListWarning from './view/ListWarning.vue';

export default {
  props: ['moduleID'],
  components: {
    FLessonListItem,
    SlickList,
    SlickItem,
    FAddButton,
    FListWarning,
  },
  data: () => ({
    loaded: false,
  }),
  computed: {
    order() {
      return this.$store.state.system.listControl.order;
    },
    allowEdit() {
      return (
        this.$store.state.auth.user.type == UserType.Admin ||
        this.$store.state.auth.user.type == UserType.TSM
      );
    },
    course() {
      return this.$store.state.course.current;
    },
    module() {
      return this.$store.state.module.current;
    },
    lessons: {
      get() {
        if (this.$store.getters['course/isLinear']) {
          return this.course.modules;
        } else {
          const module = this.course.modules.find(
            item => item._id == this.moduleID
          );
          return module.lessons;
        }
      },
      set(value) {
        if (this.$store.getters['course/isLinear']) {
          this.course.modules = value;
        } else {
          const module = this.course.modules.find(
            item => item._id == this.moduleID
          );
          module.lessons = value;
        }
      },
    },
    currentDescription() {
      return stringsHelper.convertBreakLinesToBrTag(
        this.course.description[this.currentLanguage]
      );
    },
    courseTitle() {
      return this.course.title[this.currentLanguage];
    },
    languages() {
      return this.course && this.course.langs ? this.course.langs : [];
    },
    currentLanguage() {
      return this.languages.includes(this.$i18n.locale)
        ? this.$i18n.locale
        : this.languages[0];
    },
  },
  created() {
    this.get();
  },
  methods: {
    async get() {
      var progress = await this.$store.dispatch(
        'progress/loadCourseProgress',
        this.$route.params.id
      );
      this.$store.state.course.progress = progress;
      await this.$store.dispatch('auth/getData', ['examAttempts']);
      this.loaded = true;
    },
    saveOrder() {
      this.order.saved = false;
      this.order.timeoutCtl = setTimeout(async () => {
        this.lessons = this.lessons.map((item, index) => {
          item.position = index;
          return item;
        });

        await this.$store.dispatch('course/module_lesson_ordersave', {
          courseID: this.$route.params.id,
          moduleID: this.moduleID,
          lessons: this.lessons,
        });
        this.$store.commit('snackbar/show', {
          content: this.$t('alerts.courses.successSavedOrder'),
          type: 'success',
        });
        this.order.saved = true;
        setTimeout(
          function() {
            this.order.willBeSaved = false;
            setTimeout(
              function() {
                this.order.saved = false;
              }.bind(this),
              800
            );
          }.bind(this),
          2000
        );
      }, 2000);
    },
    startOrder() {
      this.order.willBeSaved = true;
      clearTimeout(this.order.timeoutCtl);
    },
  },
};
</script>
