<template>
  <div class="user-exam-attempt d-flex justify-space-between align-center">
    <div class="user-exam-attempt__name">
      <strong>{{ item.name }}</strong>
      <br />
      <small>{{ item.email }}</small>
    </div>
    <div class="user-exam-attempt__attempts">
      <div v-if="item.passed" class="d-flex justify-space-between align-center">
        <v-icon>mdi-check</v-icon>
      </div>
      <div
        v-if="!item.passed"
        class="d-flex justify-space-between align-center"
      >
        <v-text-field
          class="user-exam-attempt__attempts__input"
          v-model="model"
          hide-details
          min="0"
          :max="item.attempts"
          type="number"
          :disabled="+model === 0"
          :label="$i18n.t('labels.attempts')"
        />
        <v-btn
          small
          color="primary"
          @click.prevent="reset"
          :disabled="+model === 0"
        >
          {{ $i18n.t('labels.reset') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
  },
  data() {
    return {
      model: this.item.attempts,
    };
  },
  methods: {
    reset() {
      this.model = 0;
    },
  },
  watch: {
    model: function(value) {
      if (value !== +this.item.attempts) {
        this.$emit('change', this.item, +value);
      }
    },
    'item.attempts': function(value) {
      this.model = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.user-exam-attempt {
  background: #f5f6f6;
  padding: 10px 15px;
  border-radius: 4px;
  margin: 0 0 5px 0;
  &__name {
    line-height: 1.4;
  }
  &__attempts {
    &__input {
      width: 70px;
      margin-right: 15px;
    }
  }
}
</style>
