<template>
  <a class="add-module-btn" @click="add" v-if="allowEdit">
    <v-icon>mdi-plus</v-icon>
  </a>
</template>

<script lang="js">
import { UserType } from '../../../helpers/user_utils';

export default {
	computed: {
		allowEdit() {
      return (
        this.$store.state.auth.user.type == UserType.Admin ||
        this.$store.state.auth.user.type == UserType.TSM
      );
    },
		course() {
			return this.$store.state.course.current
		},
	},
	methods: {
		add() {
			if(
				this.$route.name == 'course-view' &&
				this.$store.getters['course/isLinear']
			) {
				this.$router.push({
				  name: "course-linear-lesson-create",
				  params: {
				    courseid: this.$route.params.id,
				  }
				});
			} else if(
				this.$route.name == 'course-view' &&
				!this.$store.getters['course/isLinear']
			) {
				this.$router.push({
				  name: "course-module-create",
				  params: {
				    courseid: this.$route.params.id,
				  }
				});
			}
    },
	}
}
</script>
