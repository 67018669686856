<template>
  <div>
    <lms-default-loader v-if="isLoading" />

    <div v-if="!isLoading">
      <f-page-header :title="courseTitle" :prevLinks="prevLinks">
        <template #actions>
          <div v-allowed="[userTypes.ADMIN]">
            <lms-page-header-action-btn icon="mdi-plus" @click="edit">
              {{ $t('labels.edit') }}
            </lms-page-header-action-btn>
            <lms-page-header-action-btn icon="mdi-delete" @click="remove">
              {{ $t('labels.remove') }}
            </lms-page-header-action-btn>
            <lms-page-header-action-spacer />

            <lms-page-header-action-btn icon="mdi-plus" @click="add">
              {{ isLinear ? $t('labels.addLesson') : $t('labels.addModule') }}
            </lms-page-header-action-btn>
          </div>
        </template>
      </f-page-header>

      <f-list-warning />

      <f-container topBottom>
        <f-description />
        <f-module-list v-if="!isLinear" />
        <f-lesson-list v-else />
      </f-container>
    </div>

    <popup-dialog-users />
  </div>
</template>

<script>
import FModuleList from '../../components/Courses/view/ModuleList.vue';
import FLessonList from '../../components/Courses/LessonList.vue';
import FDescription from '../../components/Courses/view/Description.vue';
import FListWarning from '../../components/Courses/view/ListWarning.vue';
import PopupDialogUsers from '../../components/Courses/PopupDialogUsers.vue';
import { isLinear, list } from '../../services/courses';
import course from '../../router/course';

export default {
  components: {
    FModuleList,
    FDescription,
    FLessonList,
    FListWarning,
    PopupDialogUsers,
  },
  computed: {
    course() {
      return this.$store.state.course.current;
    },
    isLinear() {
      return this.$store.getters['course/isLinear'];
    },
    prevLinks() {
      var links = [
        {
          title: this.$t('labels.courses'),
          route: {
            name: 'course-list',
          },
        },
      ];
      return links;
    },
    courseTitle() {
      try {
        return this.course.title[this.courseCurrentLanguage];
      } catch (e) {
        return 'Course';
      }
    },
    courseCurrentLanguage() {
      return this.$store.getters['course/courseCurrentLanguage'];
    },
  },
  created() {
    this.prepareData();
  },
  methods: {
    async prepareData() {
      await this.$store.dispatch('course/get', this.$route.params.id);
      this.$store.commit('system/pageLoaded');
      await this.$store.dispatch('auth/getData', ['progress', 'certificates']);

      let user = this.$store.getters['auth/user'];
      const hasCertificate = user.certificates.find(
        certificate => certificate.courseID === this.course._id
      );

      // if (typeof hasCertificate != 'undefined') {
      //   const date = new Date();
      //   let examProgress = {
      //     courseID: this.course._id,
      //     modules: []
      //   }
      //   if (isLinear()) {
      //     this.course.modules.forEach(async (module) => {
      //       let moduleProgress = {
      //         current: 1,
      //         progress: 100,
      //         ids: {
      //           course: this.course._id,
      //           lesson: module._id
      //         }
      //       }
      //       if (module.category === 2) {
      //         examProgress.modules.push(
      //           {
      //             moduleID: module._id,
      //             list: {
      //               date: date,
      //               score: module.data.questions.length,
      //             }
      //           })
      //         }
      //       await this.$store.dispatch('progress/update', moduleProgress);
      //     })
      //   } else {
      //     this.course.modules.forEach(async (module) => {
      //       let moduleProgress = {
      //         current: 1,
      //         progress: 100,
      //         ids: {
      //           course: this.course._id,
      //           lesson: module._id
      //         }
      //       }
      //       module.lessons.forEach(async (lesson) => {
      //         let lessonProgress = {
      //           current: 1,
      //           progress: 100,
      //           ids: {
      //             course: this.course._id,
      //             module: module._id,
      //             lesson: lesson._id
      //           }
      //         }
      //         if (lesson.category === 2) {
      //           examProgress.modules.push(
      //             {
      //               moduleID: lesson._id,
      //               list: {
      //                 date: date,
      //                 score: lesson.data.questions.length,
      //               }
      //             }
      //           )
      //         }
      //         await this.$store.dispatch('progress/update', lessonProgress);
      //       })
      //       await this.$store.dispatch('progress/update', moduleProgress);
      //     })
      //   }
      //   await this.$store.dispatch('progress/forceUpdateExam', examProgress);
      // }
    },
    async edit() {
      const id = this.course._id;

      this.$router.push({
        name: 'course-edit',
        params: {
          id: id,
        },
      });

      this.$gtm.trackEvent({
        category: 'course-admin-actions',
        event: 'click',
        action: 'course-click',
        label: 'edit-course',
        value: `${id}`,
      });
    },
    async remove() {
      const id = this.course._id;
      var response = await this.$store.dispatch('dialog/show', {
        title: this.$t('alerts.courses.remove.title'),
        content: this.$t('alerts.courses.remove.content'),
      });
      if (response) {
        await this.$store.dispatch('course/remove', id);
        this.$router.push({
          name: 'course-list',
        });

        this.$gtm.trackEvent({
          category: 'course-admin-actions',
          event: 'click',
          action: 'course-click',
          label: 'remove-course',
          value: `${id}`,
        });
      }
    },
    async add() {
      let type = 'lesson';
      const id = this.$route.params.id;

      if (this.$store.getters['course/isLinear']) {
        this.$router.push({
          name: 'course-linear-lesson-create',
          params: {
            courseid: id,
          },
        });
      } else {
        this.$router.push({
          name: 'course-module-create',
          params: {
            courseid: id,
          },
        });
        type = 'module';
      }

      this.$gtm.trackEvent({
        category: 'course-admin-actions',
        event: 'click',
        action: 'course-click',
        label: `add-course-${type}`,
        value: `${id}`,
      });
    },
  },
  beforeDestroy() {
    this.$store.commit('course/clearCurrent');
  },
};
</script>

<style lang="scss">
@import '../../assets/scss/base/variables';

.module_row {
  display: flex;
  align-items: center;
  height: 50px;
  background-color: $box-bg;
  padding: 0 15px;
  border-bottom: 1px solid $global-bg;
  position: relative;

  &:hover {
    background-color: #e7e7e7;
    cursor: pointer;
  }

  &.viewed {
    border-right: 5px solid rgba(0, 0, 0, 0.54);
  }

  .admin-actions {
    position: absolute;
    right: 10px;
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: end;

    a {
      display: inline-block;
      border-radius: 3px;
      width: 35px;
      height: 35px;
      /* background-color: #eeeeee; */
      margin-left: 10px;
      text-align: center;
      padding-top: 4px;
    }
  }
  .checkIcon,
  .exam-progress {
    display: inline-block;
    margin-right: 10px;
  }
}
.add-module-btn {
  display: flex;
  align-items: center;
  height: 50px;
  background-color: #ffc107;
  justify-content: center;
}
.f-rating-system {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;

  .v-rating .v-icon {
    font-size: 24px !important;
  }
}
</style>
