<template>
  <v-dialog
    v-model="active"
    scrollable
    max-width="800px"
    @click:outside="$emit('close')"
  >
    <v-card>
      <v-card-title>
        <div>
          <div class="body-2">{{ $i18n.t('labels.examAnswers') }}</div>
          <div v-if="module">
            {{ module.title[language] }}
          </div>
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="padding: 20px">
        <div v-if="loading">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <div v-if="!loading && module">
          <v-card
            elevation="0"
            class="question"
            v-for="(item, index) in module.data.questions"
            :key="index"
          >
            <v-card-title>
              {{ item.title[language] }}
            </v-card-title>
            <v-card-text>
              <div>
                <div class="d-flex">
                  <div
                    class="d-flex align-start text-caption justify-end"
                    style="width: 100px; margin-right: 20px"
                  >
                    <div>Sua Reposta</div>
                  </div>
                  <div class="align-start text-caption">Gabarito</div>
                </div>
                <div>
                  <div v-if="hasImage(item)">
                    <img
                      class="question-image"
                      :src="getImage(item)"
                      :alt="item.title[language]"
                      @click="() => openImage(item)"
                    />
                  </div>
                  <div v-if="item.type === '0'">
                    <div
                      v-for="(elem, idx) in item.answers"
                      :key="'ar_' + idx"
                      class="d-flex"
                    >
                      <div
                        class="d-flex align-center justify-end flex-shrink-0"
                        style="width: 100px; margin-right: 20px"
                      >
                        <div
                          v-if="
                            userAnswerIcon[item.id] &&
                              userAnswerIcon[item.id][elem.id]
                          "
                        >
                          <v-icon
                            :color="userAnswerIcon[item.id][elem.id].color"
                            >mdi-{{
                              userAnswerIcon[item.id][elem.id].icon
                            }}</v-icon
                          >
                        </div>
                      </div>
                      <div class="align-end">
                        <v-radio-group
                          v-model="answers[item.id]"
                          disabled
                          hide-details
                          style="margin: 0"
                        >
                          <v-radio
                            :label="elem.title[language]"
                            :value="elem.id"
                          >
                          </v-radio>
                        </v-radio-group>
                      </div>
                    </div>
                  </div>
                  <div v-else class="check_responses">
                    <div
                      v-for="(elem, idx) in item.answers"
                      :key="'ac_' + idx"
                      class="d-flex"
                    >
                      <div
                        class="d-flex align-end justify-end flex-shrink-0"
                        style="width: 100px; margin-right: 20px"
                      >
                        <div
                          v-if="
                            userAnswerIcon[item.id] &&
                              userAnswerIcon[item.id][elem.id]
                          "
                        >
                          <v-icon
                            :color="userAnswerIcon[item.id][elem.id].color"
                            >mdi-{{
                              userAnswerIcon[item.id][elem.id].icon
                            }}</v-icon
                          >
                        </div>
                      </div>
                      <div class="align-end">
                        <v-checkbox
                          v-model="answers[item.id]"
                          :label="elem.title[language]"
                          hide-details
                          :name="'check_' + item.id"
                          :value="elem.id"
                          disabled
                          style="margin: 0"
                        >
                        </v-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
        <div v-if="!loading && !module">
          {{ $i18n.t('labels.errorLoadAnswers') }}
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="blue darken-1" text @click="$emit('close')">
          {{ $i18n.t('labels.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import apiUtils from '../../helpers/api_utils';
export default {
  props: {
    module: Object,
    lastExamAttempt: Object,
    active: Boolean,
    loading: Boolean,
    language: String,
  },
  data() {
    return {
      answers: {},
      userAnswerIcon: {},
    };
  },
  methods: {
    getImage(item) {
      const apiURL = apiUtils.getURL();
      const url = `${apiURL}file/course/question/${item.image[this.language]}`;
      return url;
    },
    hasImage(item) {
      return item.image && item.image[this.language];
    },
    setUserAnswers() {
      if (this.module) {
        this.answers = this.module.data.questions.reduce((acc, item) => {
          if (+item.type === 0) {
            acc[item.id] = item.result;
          } else {
            acc[item.id] = item.answers
              .filter(answer => answer.check)
              .map(answer => answer.id);
          }
          return acc;
        }, {});
      }
      if (this.lastExamAttempt) {
        this.userAnswerIcon = this.lastExamAttempt.questions.reduce(
          (acc, item) => {
            if (!acc[item.questionID]) {
              acc[item.questionID] = {};
            }

            acc[item.questionID] = [this.answers[item.questionID]]
              .flat()
              .reduce((acc2, answer) => {
                if (item.answers.includes(answer)) {
                  acc2[answer] = {
                    icon: item.isRight ? 'check' : 'close',
                    color: item.isRight ? '#4CAF50' : '#F44336',
                  };
                } else {
                  acc2[answer] = {
                    icon: 'exclamation',
                    color: 'rgba(0, 0, 0, 0)',
                  };
                }
                return acc2;
              }, {});

            item.answers.forEach(answer => {
              if (!acc[item.questionID][answer]) {
                acc[item.questionID][answer] = {
                  icon: 'close',
                  color: '#F44336',
                };
              }
            });

            return acc;
          },
          {}
        );
      }
    },
  },
  watch: {
    module: function(value) {
      if (value) {
        this.$nextTick(() => this.setUserAnswers());
      }
    },
  },
};
</script>
