<template>
  <div class="desc" v-html="currentDescription"></div>
</template>

<script lang="js">
import stringsHelper from '../../../helpers/strings';

export default {
	computed: {
		course() {
			return this.$store.getters['course/current']
		},
    courseCurrentLanguage() {
			return this.$store.getters['course/courseCurrentLanguage']
    },
		currentDescription() {
			return stringsHelper.convertBreakLinesToBrTag(
				this.course.description[this.courseCurrentLanguage]
			);
		},
	}
}
</script>

<style lang="scss">
.desc {
  margin: 0 48px 20px 48px;
  line-height: 24px;

  @media only screen and (max-width: 800px) {
    margin: 0 12px 20px 12px;
  }
}
</style>
