<template>
  <div
    class="modular_module_row"
    :class="{ viewed: progress.length > 0 && progress[item._id] }"
    v-if="isLoaded"
  >
    <div class="mmr--header">
      <div class="mmr--header--title">
        <span v-if="allowEdit">
          <v-icon @click="open">{{
            expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'
          }}</v-icon>
        </span>
        <span
          v-handle
          class="handle"
          v-if="allowEdit"
          v-allowed="[userTypes.ADMIN]"
        >
          <v-icon>mdi-dots-grid</v-icon>
        </span>
        <div class="mmr--title-text">
          {{ item.title[courseCurrentLanguage] }}
          <v-chip class="float-right">{{
            `${lessonsCount}  ${$t('labels.lessons')}`
          }}</v-chip>
        </div>
      </div>
      <div class="mmr--actions" v-allowed="[userTypes.ADMIN]">
        <div @click="addLesson(item._id)">
          <v-icon>mdi-plus</v-icon>
        </div>
        <div @click="edit(item._id)"><v-icon>mdi-pencil</v-icon></div>
        <div @click="remove(item._id)"><v-icon>mdi-delete</v-icon></div>
      </div>
    </div>

    <div class="mmr--content">
      <f-expandable-div :expanded="expanded">
        <f-lesson-list :moduleID="item._id" />
        <div class="mmr--spacer">&nbsp;</div>
      </f-expandable-div>
    </div>
  </div>
</template>

<script>
import { HandleDirective } from 'vue-slicksort';
import FExpandableDiv from '../../shared/ExpandableDiv.vue';
import FLessonList from '../LessonList.vue';

export default {
  props: {
    item: Object,
  },
  components: {
    FExpandableDiv,
    FLessonList,
  },
  directives: {
    handle: HandleDirective,
  },
  data: () => ({
    progress: [],
    expanded: true,
  }),
  computed: {
    allowEdit() {
      return true;
    },
    course() {
      return this.$store.getters['course/current'];
    },
    courseCurrentLanguage() {
      return this.$store.getters['course/courseCurrentLanguage'];
    },
    isLoaded() {
      let isLoaded = false;
      try {
        if (this.item.title[this.courseCurrentLanguage]) {
          isLoaded = true;
        }
      } catch (e) {}
      return isLoaded;
    },
    lessonsCount() {
      const [mdl] = this.course.modules.filter(mdl => mdl._id == this.item._id);
      return mdl.lessons.length;
    },
  },
  methods: {
    open() {
      this.expanded = !this.expanded;
    },
    view(id) {
      if (this.course.structure == 'linear') {
        this.$router.push({
          name: 'course-lesson-view',
          params: {
            courseid: this.course._id,
            lessonid: id,
          },
        });
      } else {
        this.$router.push({
          name: 'course-module-view',
          params: {
            courseid: this.course._id,
            moduleid: id,
          },
        });
      }
    },
    addLesson(moduleid) {
      const courseId = this.$route.params.id;
      this.$router.push({
        name: 'course-modular-lesson-create',
        params: {
          courseid: courseId,
          moduleid,
        },
      });

      this.$gtm.trackEvent({
        category: 'course-admin-actions',
        event: 'click',
        action: 'course-click',
        label: 'add-lesson',
        value: `add-${courseId}`,
      });
    },
    edit(moduleid) {
      const courseId = this.$route.params.id;

      this.$router.push({
        name: 'course-module-edit',
        params: {
          courseid: courseId,
          moduleid,
        },
      });

      this.$gtm.trackEvent({
        category: 'course-admin-actions',
        event: 'click',
        action: 'course-click',
        label: 'edit-module',
        value: `edit-course-${courseId}-module-${moduleid}`,
      });
    },
    async remove(moduleID) {
      const courseId = this.$route.params.id;
      var response = await this.$store.dispatch('dialog/show', {
        title: this.$t('alerts.courses.removeModule.title'),
        content: this.$t('alerts.courses.removeModule.content'),
      });
      if (response) {
        await this.$store.dispatch('module/remove', moduleID);
        document.location.reload();

        this.$gtm.trackEvent({
          category: 'course-admin-actions',
          event: 'click',
          action: 'course-click',
          label: 'remove-module',
          value: `remove-course-${courseId}-module-${moduleID}`,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.modular_module_row {
  background-color: #fff;
  margin-bottom: 2px;

  .mmr--header {
    display: flex;
    justify-content: space-between;
    padding: 10px;

    .mmr--header--title {
      display: flex;
      align-items: center;
      flex-grow: 1;

      .handle {
        margin-left: 5px;
        cursor: grab;
      }
    }

    .mmr--title-text {
      font-size: 20px;
      font-weight: 500;
      min-width: 500px;
      width: 100%;
      display: inline-block;
      cursor: pointer;
      padding-left: 12px;
    }

    .mmr--actions {
      display: flex;

      div {
        margin-left: 24px;
        cursor: pointer;
      }
    }
  }
  .mmr--content {
    background-color: #f5f6f6;
  }

  .mmr--spacer {
    height: 50px;
  }
}
</style>
