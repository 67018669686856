import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"scrollable":"","max-width":"800px"},on:{"click:outside":function($event){return _vm.$emit('close')}},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c(VCard,[_c(VCardTitle,[_c('div',[_c('div',{staticClass:"body-2"},[_vm._v(_vm._s(_vm.$i18n.t('labels.examAnswers')))]),(_vm.module)?_c('div',[_vm._v(" "+_vm._s(_vm.module.title[_vm.language])+" ")]):_vm._e()])]),_c(VDivider),_c(VCardText,{staticStyle:{"padding":"20px"}},[(_vm.loading)?_c('div',[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e(),(!_vm.loading && _vm.module)?_c('div',_vm._l((_vm.module.data.questions),function(item,index){return _c(VCard,{key:index,staticClass:"question",attrs:{"elevation":"0"}},[_c(VCardTitle,[_vm._v(" "+_vm._s(item.title[_vm.language])+" ")]),_c(VCardText,[_c('div',[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex align-start text-caption justify-end",staticStyle:{"width":"100px","margin-right":"20px"}},[_c('div',[_vm._v("Sua Reposta")])]),_c('div',{staticClass:"align-start text-caption"},[_vm._v("Gabarito")])]),_c('div',[(_vm.hasImage(item))?_c('div',[_c('img',{staticClass:"question-image",attrs:{"src":_vm.getImage(item),"alt":item.title[_vm.language]},on:{"click":() => _vm.openImage(item)}})]):_vm._e(),(item.type === '0')?_c('div',_vm._l((item.answers),function(elem,idx){return _c('div',{key:'ar_' + idx,staticClass:"d-flex"},[_c('div',{staticClass:"d-flex align-center justify-end flex-shrink-0",staticStyle:{"width":"100px","margin-right":"20px"}},[(
                          _vm.userAnswerIcon[item.id] &&
                            _vm.userAnswerIcon[item.id][elem.id]
                        )?_c('div',[_c(VIcon,{attrs:{"color":_vm.userAnswerIcon[item.id][elem.id].color}},[_vm._v("mdi-"+_vm._s(_vm.userAnswerIcon[item.id][elem.id].icon))])],1):_vm._e()]),_c('div',{staticClass:"align-end"},[_c(VRadioGroup,{staticStyle:{"margin":"0"},attrs:{"disabled":"","hide-details":""},model:{value:(_vm.answers[item.id]),callback:function ($$v) {_vm.$set(_vm.answers, item.id, $$v)},expression:"answers[item.id]"}},[_c(VRadio,{attrs:{"label":elem.title[_vm.language],"value":elem.id}})],1)],1)])}),0):_c('div',{staticClass:"check_responses"},_vm._l((item.answers),function(elem,idx){return _c('div',{key:'ac_' + idx,staticClass:"d-flex"},[_c('div',{staticClass:"d-flex align-end justify-end flex-shrink-0",staticStyle:{"width":"100px","margin-right":"20px"}},[(
                          _vm.userAnswerIcon[item.id] &&
                            _vm.userAnswerIcon[item.id][elem.id]
                        )?_c('div',[_c(VIcon,{attrs:{"color":_vm.userAnswerIcon[item.id][elem.id].color}},[_vm._v("mdi-"+_vm._s(_vm.userAnswerIcon[item.id][elem.id].icon))])],1):_vm._e()]),_c('div',{staticClass:"align-end"},[_c(VCheckbox,{staticStyle:{"margin":"0"},attrs:{"label":elem.title[_vm.language],"hide-details":"","name":'check_' + item.id,"value":elem.id,"disabled":""},model:{value:(_vm.answers[item.id]),callback:function ($$v) {_vm.$set(_vm.answers, item.id, $$v)},expression:"answers[item.id]"}})],1)])}),0)])])])],1)}),1):_vm._e(),(!_vm.loading && !_vm.module)?_c('div',[_vm._v(" "+_vm._s(_vm.$i18n.t('labels.errorLoadAnswers'))+" ")]):_vm._e()]),_c(VDivider),_c(VCardActions,[_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$i18n.t('labels.close'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }