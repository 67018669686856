import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module_row",class:{ viewed: _vm.progress.length > 0 && _vm.progress[_vm.item._id] },on:{"click":function($event){return _vm.view(_vm.item._id)}}},[_c('div',{directives:[{name:"allowed",rawName:"v-allowed",value:([_vm.userTypes.STUDENT, _vm.userTypes.MASTER, _vm.userTypes.TSM]),expression:"[userTypes.STUDENT, userTypes.MASTER, userTypes.TSM]"}],staticClass:"llr--icon"},[(_vm.isLessonDone)?_c(VIcon,{class:{
        notApproved: _vm.item.category == 2 && !_vm.examResult(_vm.item).approved,
      },attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.item.category == 2 && !_vm.examResult(_vm.item).approved ? 'mdi-alert-circle' : 'mdi-checkbox-marked-circle')+" ")]):_c(VIcon,[_vm._v(" mdi-checkbox-blank-circle-outline ")])],1),_c('span',{directives:[{name:"handle",rawName:"v-handle"},{name:"allowed",rawName:"v-allowed",value:([_vm.userTypes.ADMIN]),expression:"[userTypes.ADMIN]"}],staticClass:"handle"},[_c(VIcon,[_vm._v("mdi-dots-grid")])],1),_vm._v(" "+_vm._s(_vm.getTitle(_vm.item))+" "),_c('div',{staticClass:"admin-actions"},[(!_vm.allowEdit)?_c('div',{staticClass:"f-rating-system"},[(_vm.item.category !== 2)?_c('f-rating-system',{attrs:{"rating":{
          size: 28,
          parentID: _vm.item._id,
          isReadonly: true,
          value: _vm.item.average_rating_module || 0,
        }}}):_c('div',{staticClass:"f-progress-user"},[(_vm.currentProgress != 100)?_c('div',[_vm._v("-")]):_c('div',{class:{ approved: _vm.examResult().approved }},[_vm._v(" "+_vm._s(_vm.examResult().perc)+"% ("+_vm._s(_vm.examResult().result)+"/"+_vm._s(_vm.examResult().max)+") ")])])],1):_vm._e(),_c('div',{directives:[{name:"allowed",rawName:"v-allowed",value:([_vm.userTypes.ADMIN]),expression:"[userTypes.ADMIN]"}],staticClass:"f-admin-options"},[(_vm.item.category == '2' && !_vm.getCheck(_vm.item._id))?_c('div',{staticClass:"checkIcon"},[_c(VIcon,[_vm._v("mdi-exclamation")])],1):_vm._e(),(
          _vm.item.category == '2' && _vm.getModuleProgress(_vm.item._id, true) !== false
        )?_c('div',{staticClass:"exam-progress"},[_vm._v(" "+_vm._s(_vm.getMaxScore(_vm.item))+" ")]):_vm._e(),_c('div',{directives:[{name:"allowed",rawName:"v-allowed",value:([_vm.userTypes.ADMIN]),expression:"[userTypes.ADMIN]"}]},[(_vm.isLessonDone)?_c(VIcon,{class:{
            notApproved: _vm.item.category == 2 && !_vm.examResult(_vm.item).approved,
          },attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.item.category == 2 && !_vm.examResult(_vm.item).approved ? 'mdi-alert-circle' : 'mdi-checkbox-marked-circle')+" ")]):_c(VIcon,[_vm._v(" mdi-checkbox-blank-circle-outline ")])],1),(_vm.canSeeAnswers(_vm.item))?_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.openDialogAnswers(_vm.item._id)}}},[_c(VIcon,[_vm._v("mdi-order-bool-ascending-variant")])],1):_vm._e(),(_vm.item.category == '2')?_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.openModalUsers(_vm.item._id)}}},[_c(VIcon,[_vm._v("mdi-account-multiple")])],1):_vm._e(),_c('a',{directives:[{name:"allowed",rawName:"v-allowed",value:([_vm.userTypes.ADMIN]),expression:"[userTypes.ADMIN]"}],on:{"click":function($event){$event.stopPropagation();return _vm.lessonEdit(_vm.item._id)}}},[_c(VIcon,[_vm._v("mdi-pencil")])],1),_c('a',{directives:[{name:"allowed",rawName:"v-allowed",value:([_vm.userTypes.ADMIN]),expression:"[userTypes.ADMIN]"}],on:{"click":function($event){$event.stopPropagation();return _vm.lessonRemove(_vm.item._id)}}},[_c(VIcon,[_vm._v("mdi-delete")])],1)])]),(true)?_c('f-exam-answers',{attrs:{"active":_vm.dialogAnswers,"loading":_vm.examAnswersLoading,"module":_vm.examAnswers.module,"lastExamAttempt":_vm.examAnswers.lastExamAttempt,"language":_vm.$store.getters['course/courseCurrentLanguage']},on:{"close":_vm.closeDialogAnswers}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }